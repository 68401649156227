const Types = {
  //Propierties
  GET_PROPERTIES: "GET_PROPERTIES",
  GET_PROPERTIES_START: "GET_PROPERTIES_START",
  GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS",
  GET_PROPERTIES_ERROR: "GET_PROPERTIES_ERROR",
  //Property Active
  ACTIVE_PROPERTY: "ACTIVE_PROPERTY",
  ACTIVE_PROPERTY_START: "ACTIVE_PROPERTY_START",
  ACTIVE_PROPERTY_SUCCESS: "ACTIVE_PROPERTY_SUCCESS",
  ACTIVE_PROPERTY_ERROR: "ACTIVE_PROPERTY_ERROR",
  //Paginate
  CURRENT_PAGE_PROPERTY: "CURRENT_PAGE_PROPERTY",
  CURRENT_PAGE_PROPERTY_SUCCESS: "CURRENT_PAGE_PROPERTY_SUCCESS",
  CURRENT_PAGE_PROPERTY_ERROR: "CURRENT_PAGE_PROPERTY_ERROR"
};

export default Types;
